<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="businessType">
          <el-select
              v-model="query.businessType"
              clearable
              placeholder="请选择-类型"
              style="width: 260px"
          >
            <el-option label="充值" value="1"></el-option>
            <el-option label="支付" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="sourcePort">
          <el-select
              v-model="query.sourcePort"
              clearable
              placeholder="请选择-来源端"
              style="width: 260px"
          >
            <el-option label="IOS" value="1"></el-option>
            <el-option label="android" value="2"></el-option>
            <el-option label="巡检充值" value="3"></el-option>
            <el-option label="其他" value="4"></el-option>
            <el-option label="app端的H5" value="5"></el-option>
            <el-option label="商户端" value="6"></el-option>
            <el-option label="用户端" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="paymentMethod">
          <el-select
              v-model="query.paymentMethod"
              clearable
              placeholder="请选择-用户付款方式"
              style="width: 260px"
          >
            <el-option label="支付宝" value="0"></el-option>
            <el-option label="微信" value="1"></el-option>
            <el-option label="农行" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="paymentChannel">
          <el-select
              v-model="query.paymentChannel"
              clearable
              placeholder="请选择-支付渠道"
              style="width: 260px"
          >
            <el-option label="支付宝" value="0"></el-option>
            <el-option label="微信" value="1"></el-option>
            <el-option label="农行" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="manualOperation">
          <el-select
              v-model="query.manualOperation"
              clearable
              placeholder="请选择-人工操作"
              style="width: 260px"
          >
            <el-option label="无" value="0"></el-option>
            <el-option label="人工冲正" value="-1"></el-option>
            <el-option label="人工冲红" value="-2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="settlementType">
          <el-select
              v-model="query.settlementType"
              clearable
              placeholder="请选择-结算类型"
              style="width: 260px"
          >
            <el-option label="平台结算" value="1"></el-option>
            <el-option label="独立结算" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="plateId">
          <!-- <el-input
            v-model="query.plateNumber"
            placeholder="搜索车牌号码"
            style="width: 260px"
          ></el-input> -->
          <NumplateSuggest v-model="query.plateId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="userTel">
          <el-input
              v-model="query.userTel"
              clearable
              placeholder="请输入手机号码"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="thirdNo">
          <el-input
              v-model="query.thirdNo"
              clearable
              placeholder="平台流水号"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="outThirdNo">
          <el-input
              v-model="query.outThirdNo"
              clearable
              placeholder="第三方流水号"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="regionId">
          <el-cascader v-model="defaultRegion" :clearable="true" :filterable="true" :props="props"
                       :show-all-levels="false" placeholder="请选择区域"/>
        </el-form-item>
        <el-form-item prop="searchDate">
          <el-date-picker
              v-model="searchDate"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
          <span style="margin-left: 30px"
          >合计金额<span class="total-size"
          >{{ parseFloat(totalMoney).toFixed(2) }} </span
          >元</span
          >
        </div>
        <div class="op-extra">
          <icon-button
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column
              align="center"
              fixed="left"
              label="用户手机号码"
              prop="userTel"
              width="200"
          >
          </el-table-column>
          <el-table-column align="center" label="车牌号码" width="150">
            <template slot-scope="scope">
              <Numplate :type="scope.row.plateColor">{{
                  scope.row.plateNumber
                }}
              </Numplate>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="name"
            label="订单类型"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.orderType !== undefined">{{
                orderType[scope.row.orderType]
              }}</span>
            </template>
          </el-table-column> -->
          <el-table-column
              align="center"
              label="金额（元）"
              prop="amount"
              width="100"
          >
            <template slot-scope="scope">
              {{ parseFloat(scope.row.amount).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="支付渠道" width="160">
            <template slot-scope="scope">
              <span>{{
                  paymentChannel[scope.row.paymentChannel.toString()]
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="来源端" width="160">
            <template slot-scope="scope">
              <span>{{
                  scope.row.sourcePort === 1
                      ? "IOS"
                      : scope.row.sourcePort === 2
                          ? "android"
                          : scope.row.sourcePort === 3
                              ? "巡检充值"
                              : scope.row.sourcePort === 4
                                  ? "其他"
                                  : scope.row.sourcePort === 5
                                      ? "app端的H5"
                                      : scope.row.sourcePort === 6
                                          ? "商户端"
                                          : "用户端"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="用户付款方式" width="160">
            <template slot-scope="scope">
              <!-- <span>{{
                paymentMethod[(scope.row.paymentMethod).toString()]
              }}</span> -->
              <span>{{
                  scope.row.paymentMethod === -1
                      ? ""
                      : scope.row.paymentMethod === 1
                          ? "微信"
                          : scope.row.paymentMethod === 0
                              ? "支付宝"
                              : "农行"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="类型" width="160">
            <template slot-scope="scope">
              <span>{{ scope.row.businessType === 1 ? "充值" : "支付" }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="结算方式" width="180">
            <template slot-scope="scope">
              <span>{{
                  scope.row.settlementType === 1 ? "平台结算" : "独立结算"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="城区" prop="regionName" width="160">
          </el-table-column>
          <el-table-column
              align="center"
              label="到账时间"
              prop="arrivalTime"
              width="200"
          >
          </el-table-column>
          <el-table-column label="平台流水号" prop="thirdNo" width="300">
          </el-table-column>
          <el-table-column label="第三方流水号" prop="outThirdNo" width="200">
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getRegionList} from "@/api/user";
import Numplate from "@/components/Numplate";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import {searchIncomeFlow} from "@/api/finance";
import {doExport} from "@/api/common";
import NumplateSuggest from "@/components/NumplateSuggest";

export default {
  name: "income-flow",
  mixins: [BaseMixin, Permission],
  data() {
    return {
      searchDate: [], //搜索日期的范围
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({regionId: regionId})
              .then(({returnObject}) => {
                console.log('getRegionList:', returnObject)
                const nodes = returnObject.map(item => ({
                      value: item.id,
                      label: item.name,
                      leaf: item.level == 3,
                    })
                )
                resolve(nodes)
              })
        }
      },
      query: {
        businessType: "", //收入类型（1充值，2支付）
        sourcePort: "", //来源端（1.IOS，2.android ， 3.巡检充值 ，4其他 ，5.app端的H5 ， 6.商户端 ，7.用户端）
        paymentMethod: "", //付款方式（0微信，1支付宝，2农行）
        paymentChannel: "", //支付渠道（0微信，1支付宝，2农行）
        manualOperation: "", //人工操作（0无，-1人工冲正，-2人工冲红）
        settlementType: "", //结算类型（1平台结算，2独立结算）
        plateId: "", //车牌号
        userTel: "", //手机号
        thirdNo: "", //平台流水号
        outThirdNo: "", //第三方流水号
        regionId: "",
        startDate: "",
        endDate: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      paymentChannel: {
        "-1": "",
        0: "支付宝",
        1: "微信",
        2: "农行",
      },
      totalMoney: 0, //总金额
      dataList: [], //查询到的数据
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
    };
  },
  components: {
    Numplate,
    NumplateSuggest,
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.searchDate = [];
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      this.isLoading = true;
      this.query.region = this.defaultRegion.length == 3 ? this.defaultRegion[2] : this.defaultRegion[1]
      const params = this.paramFormat(this.query);
      if (this.searchDate.length > 0) {
        params["startDate"] = this.searchDate[0];
        params["endDate"] = this.searchDate[1];
      }
      const res = await searchIncomeFlow(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.list;
        this.pagination.totalSize = returnObject.total;
        this.pagination.totalPages = returnObject.pages;
        this.form.exportSize = returnObject.total;
        this.totalMoney = returnObject.totalAmount;
      }
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.searchDate.length > 0) {
        params["startDate"] = this.searchDate[0];
        params["endDate"] = this.searchDate[1];
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      // const res = searchIncomeFlow(params);
      // var text = window.location.hostname;
      // console.log(text);
      // window.open("http://172.16.69.129:81" + "/MoneyManagement/searchIncomeFlow?" + objectToQueryString(params));
      doExport("/MoneyManagement/searchIncomeFlow", params, "收入流水.xlsx");
      // window.open("/api" + "/MoneyManagement/searchIncomeFlow?" + objectToQueryString(params));

      loading.close();
      this.exportDialogFormVisible = false;

      // if (res && res.code === 30 && res.result) {
      //   loading.close();
      //   this.exportDialogFormVisible = false;
      // }
    },
  },
};
</script>

<style lang="less" scoped>
</style>
